<template>
  <section>
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="card-title">
              <router-link to="/audit">
                <span class="float-right btn btn-info"> retour </span>
              </router-link>
            </div>
            <div class="container">
              <form @submit.prevent="updateraudit()">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="first"
                        >Nom <span class="obligatoire">*</span>
                      </label>

                      <input
                        v-model="nom"
                        type="text"
                        class="form-control"
                        placeholder=""
                        id="first"
                        required
                      />
                    </div>
                  </div>

                  <!--  col-md-6   -->

                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="last"
                        >Photo<span class="obligatoire">*</span></label
                      >
                      <div class="input-group col-md-14">
                        <input
                          type="file"
                          class="form-control file-upload-info"
                          placeholder="Upload Image"
                          @change="uploadFile"
                          ref="file"
                        />

                        <span class="input-group-append">
                          <button
                            class="file-upload-browse btn btn-gradient-primary"
                            type="button"
                          >
                            Télécharger
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>

                  <!--  col-md-6   -->

                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="last"
                        >Produit <span class="obligatoire">*</span></label
                      >
                      <select
                        id="inputState"
                        class="form-control"
                        v-model="produit_id"
                        required
                      >
                        <option
                          v-for="del in produits"
                          :key="del._id"
                          v-bind:value="del._id"
                        >
                          {{ del.reference }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="last"
                        >commentaire<span class="obligatoire">*</span></label
                      >
                      <input
                        v-model="commentaire"
                        type="text"
                        class="form-control"
                        placeholder=""
                        id="last"
                        required
                      />
                    </div>
                  </div>

                  <!--  col-md-6   -->
                </div>

                <button type="submit" class="btn btn-primary">
                  Modifier audit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { HTTP } from "@/axios";
import moment from "moment";
export default {
  data() {
    return {
      file: "",
      nom: "",
      commentaire: "",
      produit_id: "",
      produits: [],
    };
  },
  created: function () {
    this.getAuditById();
    this.getproduits();
  },
  methods: {
    getproduits() {
      HTTP.get("produits/getproduits")
        .then((response) => {
          console.log(response.data);
          this.produits = response.data;
        })
        .then((err) => console.log(err));
    },
    uploadFile() {
      this.file = this.$refs.file.files[0];
      console.log(this.file);
    },
    getAuditById() {
      HTTP.get("audits/getAuditbyid/" + this.$route.params.id).then(
        (res) => {
          this.nom = res.data.nom;
          this.commentaire = res.data.commentaire;
          this.produit_id = res.data.produit_id;
        },
        (err) => {
          this.error = err.response.data.error;
        }
      );
    },
    updateraudit() {
      const formData = new FormData();
      formData.append("photo", this.file);
      formData.append("_id", this.$route.params.id);
      formData.append("produit_id", this.produit_id);
      formData.append("commentaire", this.commentaire);
      formData.append("nom", this.nom);

      // let audit = {
      // prix: this.prix,
      // reference: this.reference,
      // description: this.description,
      // quantites: this.quantites,
      //  quantites_minimal:this.quantites_minimal,
      // categorie:this.categorie,
      // fournisseur:this.fournisseur,
      // designation:this.designation,&
      // prix:this.prix,
      // date_construction:this.date_construction,
      // date_peremption:this.date_peremption,
      // _id:this.$route.params.id

      // };

      HTTP.put("audits/modifierAudit", formData).then(
        (res) => {
          if (res.status === 200) {
            Toast.fire({
              icon: "success",
              title: "audit modifié",
            });
            this.$router.push("/audit");
          }
        },
        (err) => {
          this.error = err.response.data.error;
        }
      );
    },
  },
};
</script>
